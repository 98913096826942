import React from "react";
import { FrameWrapper } from "../../components/FrameWrapper";
import { Group } from "../../components/Group";
import "./style.css";

export const Announcement = () => {
  return (
    <div className="announcement">
      <div className="div-23">
        <div className="overlap-21">
          <img className="background-7" alt="Background" src="/img/background1-6-2.png" />
          <img className="background-7" alt="Background" src="/img/background1-6-2.png" />
          <img className="div-24" alt="Div" src="/img/div-2.svg" />
          <div className="rectangle-4" />
          <img className="image-18" alt="Image" src="/img/image-13.png" />
          <div className="rectangle-5" />
          <div className="text-wrapper-85">Letter of Greetings_Samdech_Krala_Hom_Sar_Kheng.pdf</div>
          <img className="file-svg" alt="File svg" src="/img/file-svg-1.svg" />
          <div className="rectangle-6" />
          <div className="text-wrapper-86">Download</div>
          <FrameWrapper className="frame-92" />
          <div className="group-54">
            <div className="text-wrapper-87">RECENT ANNOUNCEMENT</div>
            <img className="line-13" alt="Line" src="/img/line-1-8.svg" />
          </div>
          <div className="group-55">
            <div className="overlap-22">
              <img className="cambodianflag-4" alt="Cambodianflag" src="/img/cambodianflag-design1-3-1.png" />
              <div className="group-56">
                <div className="overlap-23">
                  <footer className="footer-4">
                    <div className="overlap-group-14">
                      <img className="line-14" alt="Line" src="/img/line-7-4.svg" />
                      <div className="div-25">
                        <div className="p-4">
                          <p className="element-4">
                            <span className="text-wrapper-88">រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាង</span>
                            <span className="text-wrapper-89"> © 2023</span>
                            <span className="text-wrapper-88"> សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត</span>
                          </p>
                        </div>
                        <div className="ul-4">
                          <div className="frame-58">
                            <div className="frame-59">
                              <div className="text-wrapper-90">ព័ត៌មាន</div>
                              <div className="text-wrapper-91">សេចក្តីប្រកាស</div>
                              <div className="text-wrapper-92">សមិទ្ធិផល</div>
                              <div className="text-wrapper-93">ទំនាក់ទំនង</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </footer>
                  <div className="frame-60">
                    <div className="frame-61">
                      <div className="frame-62">
                        <div className="frame-63">
                          <div className="group-57" />
                          <p className="div-26">
                            <span className="text-wrapper-94">
                              បណ្ណាល័យរូបភាព
                              <br />
                            </span>
                            <span className="text-wrapper-94">
                              <br />
                            </span>
                            <span className="text-wrapper-94">{""}</span>
                          </p>
                          <div className="frame-64">
                            <div className="frame-65">
                              <img className="image-19" alt="Image" src="/img/image-14.png" />
                              <img className="image-19" alt="Image" src="/img/image-14.png" />
                              <img className="image-19" alt="Image" src="/img/image-14.png" />
                            </div>
                            <div className="frame-66">
                              <img className="image-20" alt="Image" src="/img/image-11-2x.png" />
                              <img className="image-21" alt="Image" src="/img/image-15.png" />
                              <img className="image-21" alt="Image" src="/img/image-15.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="group-58">
                        <div className="group-59">
                          <div className="frame-67">
                            <div className="frame-68">
                              <div className="text-wrapper-95">អាសយដ្ឋាន</div>
                              <p className="text-wrapper-96">
                                អគារលេខ SD001 ផ្លួវ ២០០៤ ភូមិ តាងួន ១ សង្កាត់ កាកាប ១ ខណ្ឌពោធិ៍សែនជ័យ 120912
                                រាជធានីភ្នំពេញ
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="frame-69">
                          <p className="div-27">
                            <span className="text-wrapper-94">សារអេឡិចត្រូនិច</span>
                            <span className="text-wrapper-97">
                              {" "}
                              <br />
                            </span>
                            <span className="text-wrapper-97">
                              <br />
                            </span>
                            <span className="text-wrapper-97">{""}</span>
                          </p>
                          <div className="text-wrapper-98">info@samdechhunmanet.gov.kh</div>
                        </div>
                      </div>
                      <div className="div-footer-social-4">
                        <div className="text-wrapper-99">បណ្តាញសង្គម</div>
                        <div className="frame-70">
                          <img className="group-60" alt="Group" src="/img/group-24.png" />
                          <img className="group-60" alt="Group" src="/img/group-24.png" />
                          <img className="group-60" alt="Group" src="/img/group-24.png" />
                          <img className="group-60" alt="Group" src="/img/group-24.png" />
                          <div className="list-item-link-SVG-4">
                            <div className="symbol-21"></div>
                          </div>
                          <div className="list-item-link-SVG-4">
                            <img className="telegram-app-5" alt="Telegram app" src="/img/telegram-app.png" />
                          </div>
                          <div className="symbol-22"></div>
                          <div className="symbol-23"></div>
                          <div className="symbol-24"></div>
                          <div className="symbol-25"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="subtract-4" alt="Subtract" src="/img/subtract-1.png" />
            </div>
          </div>
        </div>
        <div className="group-61">
          <p className="text-wrapper-100">Letter of Greetings to Samdech Krala Hom Sar Kheng</p>
          <img className="vector-5" alt="Vector" src="/img/vector-5.svg" />
          <img className="vector-6" alt="Vector" src="/img/vector-6.svg" />
        </div>
        <p className="text-wrapper-101">
          Letter of Greetings to Samdech Krala Hom Sar Kheng, Deputy Prime Minister and Minister of Interior
        </p>
        <div className="span-wrapper">
          <div className="span-2">
            <div className="text-wrapper-102">4 min read</div>
            <img className="svg-2" alt="Svg" src="/img/svg-6.svg" />
          </div>
        </div>
        <div className="text-wrapper-103">28th February, 2023</div>
        <img className="icon-facebook-2" alt="Icon facebook" src="/img/icon-facebook-4.png" />
        <img className="vector-7" alt="Vector" src="/img/vector-4.svg" />
        <Group className="design-component-instance-node" to="/home" to1="/biography-1" to2="/vision" to3="/contact" />
      </div>
    </div>
  );
};
