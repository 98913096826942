import React from "react";
import { Group } from "../../components/Group";
import { GroupWrapper } from "../../components/GroupWrapper";
import "./style.css";

export const Contact = () => {
  return (
    <div className="contact">
      <div className="div-28">
        <div className="overlap-24">
          <div className="section-image" />
          <div className="rectangle-7" />
        </div>
        <div className="section-2">
          <div className="group-62">
            <div className="div-relative-2">
              <img className="img-3" alt="Img" src="/img/img-4.svg" />
              <div className="text-wrapper-104">+855 88 889 7978</div>
            </div>
            <div className="div-relative-3">
              <img className="img-3" alt="Img" src="/img/img-5.svg" />
              <div className="text-wrapper-105">info@co-army.com</div>
            </div>
            <div className="div-relative-4">
              <img className="img-4" alt="Img" src="/img/img-6.svg" />
              <p className="text-wrapper-106">
                អគារលេខ SD001 ផ្លួវ ២០០៤ ភូមិ តាងួន ១ សង្កាត់ កាកាប ១ ខណ្ឌពោធិ៍សែនជ័យ 120912 រាជធានីភ្នំពេញ
              </p>
            </div>
          </div>
        </div>
        <div className="section-iframe">
          <div className="body">
            <div className="div-29">
              <div className="overlap-25">
                <img className="image-22" alt="Image" src="/img/image-1.svg" />
              </div>
              <div className="vt" />
              <div className="vt-2" />
              <div className="vt-3" />
              <div className="vt-4" />
              <div className="vt-5" />
              <div className="vt-6" />
              <div className="overlap-group-15">
                <div className="vt-7" />
                <div className="vt-8" />
                <div className="vt-9" />
                <div className="vt-10" />
                <div className="div-30">
                  <img className="button-zoom-in" alt="Button zoom in" src="/img/button-zoom-in.png" />
                  <div className="div-31" />
                  <img className="button-zoom-out" alt="Button zoom out" src="/img/button-zoom-in.png" />
                </div>
                <div className="div-32" />
                <div className="button-keyboard">Keyboard shortcuts</div>
                <div className="div-33" />
                <div className="text-wrapper-107">Map data ©2023</div>
                <div className="div-34" />
                <div className="link-terms">Terms</div>
              </div>
              <div className="vt-11" />
              <div className="button-show-wrapper">
                <div className="button-show">
                  <div className="region-map-wrapper">
                    <div className="region-map" />
                  </div>
                </div>
              </div>
              <div className="overlap-26">
                <div className="div-35">
                  <div className="div-place-name">
                    <div className="text-wrapper-108">កងវិរៈសេនាតូចលេខ១១</div>
                  </div>
                  <div className="text-wrapper-109">Phnom Penh</div>
                  <div className="link-get-directions" />
                  <div className="link-get-directions-2">
                    <div className="text-wrapper-110">Directions</div>
                  </div>
                  <div className="link-view-larger-map">View larger map</div>
                </div>
              </div>
              <div className="vt-12" />
              <div className="vt-13" />
              <div className="vt-14" />
              <div className="vt-15" />
            </div>
          </div>
        </div>
        <div className="overlap-27">
          <div className="div-px">
            <div className="section-3" />
            <div className="section-4">បណ្តាញសង្គម</div>
            <div className="section-5" />
            <div className="section-6" />
            <img className="section-link-img" alt="Section link img" src="/img/section-link-img.svg" />
            <GroupWrapper
              className="group-130"
              img="/img/img-7.svg"
              img1="/img/img-7.svg"
              img2="/img/img-7.svg"
              img3="/img/img-7.svg"
            />
          </div>
          <div className="section-link">Facebook</div>
          <img className="vector-8" alt="Vector" src="/img/vector-15.svg" />
          <div className="section-link-twitter">Twitter</div>
          <img className="vector-9" alt="Vector" src="/img/vector-16.svg" />
          <div className="section-link-2">Telegram</div>
          <img className="vector-10" alt="Vector" src="/img/vector-17.svg" />
          <div className="section-link-tiktok">Tiktok</div>
          <img className="vector-11" alt="Vector" src="/img/vector-18.svg" />
          <div className="section-link-youtube">Youtube</div>
          <img className="vector-12" alt="Vector" src="/img/vector-19.svg" />
          <div className="section-link-3">Instagram</div>
          <div className="group-63">
            <div className="list-item-button-go" />
            <div className="list-item-button-go-2" />
            <div className="list-item-button-go-3" />
            <div className="list-item-button-go-4" />
            <div className="list-item-button-go-5" />
          </div>
        </div>
        <Group className="group-64" to="/home" to1="/biography-1" to2="/vision" to3="/contact" />
        <div className="group-65">
          <div className="overlap-28">
            <img className="cambodianflag-5" alt="Cambodianflag" src="/img/cambodianflag-design1-3-1.png" />
            <div className="group-66">
              <div className="overlap-29">
                <footer className="footer-5">
                  <div className="overlap-group-16">
                    <img className="line-15" alt="Line" src="/img/line-7.svg" />
                    <div className="div-36">
                      <div className="p-5">
                        <p className="element-5">
                          <span className="text-wrapper-111">រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាង</span>
                          <span className="text-wrapper-112"> © 2023</span>
                          <span className="text-wrapper-111"> សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត</span>
                        </p>
                      </div>
                      <div className="ul-5">
                        <div className="frame-71">
                          <div className="frame-72">
                            <div className="text-wrapper-113">ព័ត៌មាន</div>
                            <div className="text-wrapper-114">សេចក្តីប្រកាស</div>
                            <div className="text-wrapper-115">សមិទ្ធិផល</div>
                            <div className="text-wrapper-116">ទំនាក់ទំនង</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
                <div className="frame-73">
                  <div className="frame-74">
                    <div className="frame-75">
                      <div className="frame-76">
                        <div className="group-67" />
                        <p className="div-37">
                          <span className="text-wrapper-117">
                            បណ្ណាល័យរូបភាព
                            <br />
                          </span>
                          <span className="text-wrapper-117">
                            <br />
                          </span>
                          <span className="text-wrapper-117">{""}</span>
                        </p>
                        <div className="frame-78">
                          <div className="frame-79">
                            <img className="image-23" alt="Image" src="/img/image-14.png" />
                            <img className="image-23" alt="Image" src="/img/image-14.png" />
                            <img className="image-23" alt="Image" src="/img/image-14.png" />
                          </div>
                          <div className="frame-80">
                            <img className="image-24" alt="Image" src="/img/image-11-2x.png" />
                            <img className="image-25" alt="Image" src="/img/image-15.png" />
                            <img className="image-25" alt="Image" src="/img/image-15.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="group-68">
                      <div className="group-69">
                        <div className="frame-81">
                          <div className="frame-82">
                            <div className="text-wrapper-118">អាសយដ្ឋាន</div>
                            <p className="text-wrapper-119">
                              អគារលេខ SD001 ផ្លួវ ២០០៤ ភូមិ តាងួន ១ សង្កាត់ កាកាប ១ ខណ្ឌពោធិ៍សែនជ័យ 120912
                              រាជធានីភ្នំពេញ
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="frame-83">
                        <p className="div-38">
                          <span className="text-wrapper-117">សារអេឡិចត្រូនិច</span>
                          <span className="text-wrapper-120">
                            {" "}
                            <br />
                          </span>
                          <span className="text-wrapper-120">
                            <br />
                          </span>
                          <span className="text-wrapper-120">{""}</span>
                        </p>
                        <div className="text-wrapper-121">info@samdechhunmanet.gov.kh</div>
                      </div>
                    </div>
                    <div className="div-footer-social-5">
                      <div className="text-wrapper-122">បណ្តាញសង្គម</div>
                      <div className="frame-84">
                        <img className="group-70" alt="Group" src="/img/group.png" />
                        <img className="group-70" alt="Group" src="/img/group.png" />
                        <img className="group-70" alt="Group" src="/img/group.png" />
                        <img className="group-70" alt="Group" src="/img/group.png" />
                        <div className="list-item-link-SVG-5">
                          <div className="symbol-26"></div>
                        </div>
                        <div className="list-item-link-SVG-5">
                          <img className="telegram-app-6" alt="Telegram app" src="/img/telegram-app.png" />
                        </div>
                        <div className="symbol-27"></div>
                        <div className="symbol-28"></div>
                        <div className="symbol-29"></div>
                        <div className="symbol-30"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="subtract-5" alt="Subtract" src="/img/subtract-1.png" />
          </div>
        </div>
      </div>
    </div>
  );
};
