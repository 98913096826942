import React from "react";
import { Group } from "../../components/Group";
import { MsftContentCard } from "../../components/MsftContentCard";
import "./style.css";

export const DetailPage = () => {
  return (
    <div className="detail-page">
      <div className="div-43">
        <div className="overlap-34">
          <img className="div-44" alt="Div" src="/img/div-4.svg" />
          <div className="group-82">
            <div className="overlap-35">
              <div className="rectangle-8" />
              <div className="group-83">
                <p className="text-wrapper-135">Prime Minister Addresses General Debate</p>
                <img className="vector-13" alt="Vector" src="/img/vector-26.svg" />
                <img className="vector-14" alt="Vector" src="/img/vector-27.svg" />
                <p className="cambodia-prime-2">
                  Cambodia - Prime Minister Addresses General Debate, <br />
                  78th Session
                </p>
                <div className="text-wrapper-136">28th February, 2023</div>
                <div className="group-84">
                  <div className="div-45">
                    <div className="span-3">
                      <div className="text-wrapper-137">4 min read</div>
                      <img className="svg-3" alt="Svg" src="/img/svg-7.svg" />
                    </div>
                  </div>
                  <img className="group-85" alt="Group" src="/img/group-62.png" />
                </div>
                <div className="overlap-36">
                  <div className="group-86">
                    <p className="having-risen-through">
                      Having risen through the ranks of the RCAF, from a low-ranking officer to the current Chief
                      Commanding Officer, he is highly respected by his peers and subordinates alike.
                      <br />
                      He is a wise leader who can pave the way for many of his subordinates to surpass their limits and
                      become a better person for themselves, family and Cambodia.
                    </p>
                    <p className="text-wrapper-138">
                      Having served in both the Cambodian-Vietnam War and border clashes against Thailand, Chief
                      Commanding Officer Hun Manet has seen his fair share of battle fields. He is experienced in
                      leading units and has a focus on ensuring their safety and efficiency.
                    </p>
                    <p className="text-wrapper-139">
                      He is well versed in military tactics and strategy. Chief Commanding Officer Hun Manet exhibits
                      leadership traits such as commitment, courage, discipline, and respect.
                    </p>
                    <div className="overlap-group-19">
                      <p className="text-wrapper-140">
                        He is committed to the cause of the RCAF and the people of Cambodia. His courage and endurance
                        are tremendous, allowing him to endure great hardships. He is disciplined when it comes to
                        following orders and setting an example.
                      </p>
                      <p className="text-wrapper-141">
                        Chief Commanding Officer Hun Manet has had an impressive career in the RCAF. He is a recipient
                        of various awards and commendations, including honorary ranks and the Medal of Honor. His
                        influence and impact on the RCAF has been widespread and he is respected by his peers and
                        subordinates alike. As a leader and commander, he has given the RCAF immense pride and dignity.
                      </p>
                    </div>
                  </div>
                  <p className="text-wrapper-142">
                    Chief Commanding Officer Hun Manet has had an impressive career in the RCAF. He is a recipient of
                    various awards and commendations, including honorary ranks and the Medal of Honor. His influence and
                    impact on the RCAF has been widespread and he is respected by his peers and subordinates alike. As a
                    leader and commander, he has given the RCAF immense pride and dignity.
                  </p>
                  <p className="text-wrapper-143">
                    Chief Commanding Officer Hun Manet has had an impressive career in the RCAF. He is a recipient of
                    various awards and commendations, including honorary ranks and the Medal of Honor. His influence and
                    impact on the RCAF has been widespread and he is respected by his peers and subordinates alike. As a
                    leader and commander, he has given the RCAF immense pride and dignity.
                  </p>
                </div>
                <p className="text-wrapper-144">
                  Dr Hun Manet addressing the delegates at the United Nations General Assembly
                </p>
                <p className="text-wrapper-145">Chief Commanding Officer pays a visit to Kampot</p>
                <div className="text-wrapper-146">RECENT NEWS</div>
                <img className="un-2" alt="Un" src="/img/un71003324-1-1-1.png" />
              </div>
            </div>
          </div>
          <div className="group-87">
            <div className="overlap-37">
              <img className="cambodianflag-7" alt="Cambodianflag" src="/img/cambodianflag-design1-3-1.png" />
              <div className="group-88">
                <div className="overlap-38">
                  <footer className="footer-7">
                    <div className="overlap-group-20">
                      <img className="line-19" alt="Line" src="/img/image.svg" />
                      <div className="div-46">
                        <div className="p-7">
                          <p className="element-7">
                            <span className="text-wrapper-147">រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាង</span>
                            <span className="text-wrapper-148"> © 2023</span>
                            <span className="text-wrapper-147"> សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត</span>
                          </p>
                        </div>
                        <div className="ul-7">
                          <div className="frame-99">
                            <div className="frame-100">
                              <div className="text-wrapper-149">ព័ត៌មាន</div>
                              <div className="text-wrapper-150">សេចក្តីប្រកាស</div>
                              <div className="text-wrapper-151">សមិទ្ធិផល</div>
                              <div className="text-wrapper-152">ទំនាក់ទំនង</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </footer>
                  <div className="frame-101">
                    <div className="frame-102">
                      <div className="frame-103">
                        <div className="frame-104">
                          <div className="group-89" />
                          <p className="div-47">
                            <span className="text-wrapper-153">
                              បណ្ណាល័យរូបភាព
                              <br />
                            </span>
                            <span className="text-wrapper-153">
                              <br />
                            </span>
                            <span className="text-wrapper-153">{""}</span>
                          </p>
                          <div className="frame-105">
                            <div className="frame-106">
                              <img className="image-29" alt="Image" src="/img/image-14.png" />
                              <img className="image-29" alt="Image" src="/img/image-14.png" />
                              <img className="image-29" alt="Image" src="/img/image-14.png" />
                            </div>
                            <div className="frame-107">
                              <img className="image-30" alt="Image" src="/img/image-11-2x.png" />
                              <img className="image-31" alt="Image" src="/img/image-15.png" />
                              <img className="image-31" alt="Image" src="/img/image-15.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="group-90">
                        <div className="group-91">
                          <div className="frame-108">
                            <div className="frame-109">
                              <div className="text-wrapper-154">អាសយដ្ឋាន</div>
                              <p className="text-wrapper-155">
                                អគារលេខ SD001 ផ្លួវ ២០០៤ ភូមិ តាងួន ១ សង្កាត់ កាកាប ១ ខណ្ឌពោធិ៍សែនជ័យ 120912
                                រាជធានីភ្នំពេញ
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="frame-110">
                          <p className="div-48">
                            <span className="text-wrapper-153">សារអេឡិចត្រូនិច</span>
                            <span className="text-wrapper-156">
                              {" "}
                              <br />
                            </span>
                            <span className="text-wrapper-156">
                              <br />
                            </span>
                            <span className="text-wrapper-156">{""}</span>
                          </p>
                          <div className="text-wrapper-157">info@samdechhunmanet.gov.kh</div>
                        </div>
                      </div>
                      <div className="div-footer-social-7">
                        <div className="text-wrapper-158">បណ្តាញសង្គម</div>
                        <div className="frame-111">
                          <img className="group-92" alt="Group" src="/img/group-42-2x.png" />
                          <img className="group-92" alt="Group" src="/img/group-42-2x.png" />
                          <img className="group-92" alt="Group" src="/img/group-42-2x.png" />
                          <img className="group-92" alt="Group" src="/img/group-42-2x.png" />
                          <div className="list-item-link-SVG-7">
                            <div className="symbol-36"></div>
                          </div>
                          <div className="list-item-link-SVG-7">
                            <img className="telegram-app-8" alt="Telegram app" src="/img/telegram-app.png" />
                          </div>
                          <div className="symbol-37"></div>
                          <div className="symbol-38"></div>
                          <div className="symbol-39"></div>
                          <div className="symbol-40"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="subtract-7" alt="Subtract" src="/img/subtract-1.png" />
            </div>
          </div>
          <MsftContentCard className="msft-content-card-instance" />
          <MsftContentCard className="msft-content-card-2" img="/img/vector-31.svg" vector="/img/vector-30.svg" />
          <MsftContentCard className="msft-content-card-3" img="/img/vector-33.svg" vector="/img/vector-32.svg" />
          <MsftContentCard className="msft-content-card-4" img="/img/vector-35.svg" vector="/img/vector-34.svg" />
          <MsftContentCard className="msft-content-card-5" img="/img/vector-25.svg" vector="/img/vector-24.svg" />
        </div>
        <Group className="group-93" to="/home" to1="/biography-1" to2="/vision" to3="/contact" />
      </div>
    </div>
  );
};
