/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const Component = ({
  property1,
  className,
  frameClassName,
  arrow = "/img/arrow-3.svg",
  line = "/img/line-1.svg",
  img = "/img/line-2.svg",
  arrow1 = "/img/arrow-3.svg",
  line1 = "/img/line-1.svg",
  line2 = "/img/line-2.svg",
  arrow2 = "/img/arrow-3.svg",
  line3 = "/img/line-1.svg",
  line4 = "/img/line-2.svg",
  arrow3 = "/img/arrow-3.svg",
  line5 = "/img/line-1.svg",
  line6 = "/img/line-2.svg",
  to,
}) => {
  return (
    <div className={`component ${className}`}>
      <div className={`frame-3 ${frameClassName}`}>
        <Link className="frame-4" to={to}>
          <img className="screenshot" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-2.gif" />
          <div className="heading-link">នាយករដ្ឋមន្ត្រីថ្លែងសុន្ទរកថាទូទៅ ក្នុងប្រជុំលើកទី៧៨ UN</div>
          <p className="p">
            អគ្គលេខាធិការអាស៊ាន លោកបណ្ឌិត កៅ គឹមហួន ចូលជួបសម្តែងការគួរសមជាមួយ សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត
          </p>
          <div className="overlap-group-3">
            <div className="text-wrapper-7">ព័​ត៍​មានបន្ថែម</div>
            <img className="arrow" alt="Arrow" src={arrow} />
          </div>
          <div className="text-wrapper-8">១០ តុលា ២០២៣</div>
          <img className="line" alt="Line" src={line} />
          <img className="img" alt="Line" src={img} />
        </Link>
        <div className="frame-4">
          <img className="screenshot-2" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-1-2.gif" />
          <div className="heading-link">នាយករដ្ឋមន្ត្រីថ្លែងសុន្ទរកថាទូទៅ ក្នុងប្រជុំលើកទី៧៨ UN</div>
          <p className="p">
            អគ្គលេខាធិការអាស៊ាន លោកបណ្ឌិត កៅ គឹមហួន ចូលជួបសម្តែងការគួរសមជាមួយ សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត
          </p>
          <div className="overlap-group-3">
            <div className="text-wrapper-7">ព័​ត៍​មានបន្ថែម</div>
            <img className="arrow" alt="Arrow" src={arrow1} />
          </div>
          <div className="text-wrapper-8">១០ តុលា ២០២៣</div>
          <img className="line" alt="Line" src={line1} />
          <img className="img" alt="Line" src={line2} />
        </div>
        <div className="frame-4">
          <img className="screenshot-2" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-1-3.gif" />
          <div className="heading-link">នាយករដ្ឋមន្ត្រីថ្លែងសុន្ទរកថាទូទៅ ក្នុងប្រជុំលើកទី៧៨ UN</div>
          <p className="p">
            អគ្គលេខាធិការអាស៊ាន លោកបណ្ឌិត កៅ គឹមហួន ចូលជួបសម្តែងការគួរសមជាមួយ សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត
          </p>
          <div className="overlap-group-3">
            <div className="text-wrapper-7">ព័​ត៍​មានបន្ថែម</div>
            <img className="arrow" alt="Arrow" src={arrow2} />
          </div>
          <div className="text-wrapper-8">១០ តុលា ២០២៣</div>
          <img className="line" alt="Line" src={line3} />
          <img className="img" alt="Line" src={line4} />
        </div>
        <div className="frame-4">
          <img className="screenshot" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-2.gif" />
          <div className="heading-link">នាយករដ្ឋមន្ត្រីថ្លែងសុន្ទរកថាទូទៅ ក្នុងប្រជុំលើកទី៧៨ UN</div>
          <p className="p">
            អគ្គលេខាធិការអាស៊ាន លោកបណ្ឌិត កៅ គឹមហួន ចូលជួបសម្តែងការគួរសមជាមួយ សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត
          </p>
          <div className="overlap-group-3">
            <div className="text-wrapper-7">ព័​ត៍​មានបន្ថែម</div>
            <img className="arrow" alt="Arrow" src={arrow3} />
          </div>
          <div className="text-wrapper-8">១០ តុលា ២០២៣</div>
          <img className="line" alt="Line" src={line5} />
          <img className="img" alt="Line" src={line6} />
        </div>
      </div>
    </div>
  );
};

Component.propTypes = {
  property1: PropTypes.oneOf(["default"]),
  arrow: PropTypes.string,
  line: PropTypes.string,
  img: PropTypes.string,
  arrow1: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  arrow2: PropTypes.string,
  line3: PropTypes.string,
  line4: PropTypes.string,
  arrow3: PropTypes.string,
  line5: PropTypes.string,
  line6: PropTypes.string,
  to: PropTypes.string,
};
