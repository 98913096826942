import React from "react";
import { Link } from "react-router-dom";
import { Group } from "../../components/Group";
import "./style.css";

export const DivWrapper = () => {
  return (
    <div className="div-wrapper">
      <div className="vision-3">
        <div className="group-94">
          <Link to="/vision">
            <button className="button-2">PENTAGON STRATEGY</button>
          </Link>
          <div className="button-statements-3">QUOTES</div>
        </div>
        <div className="group-95">
          <div className="overlap-group-21">
            <img className="line-20" alt="Line" src="/img/line-11.svg" />
            <img className="line-21" alt="Line" src="/img/line-12.svg" />
          </div>
        </div>
        <Group
          assetHero="/img/asset-1new-hero-2-2x.png"
          className="group-98"
          imageClassName="group-96"
          linkImageClassName="group-97"
          to="/home"
          to1="/biography-1"
          to2="/vision"
          to3="/contact"
        />
        <div className="group-99">
          <div className="overlap-39">
            <img className="cambodianflag-8" alt="Cambodianflag" src="/img/cambodianflag-design1-3-2.png" />
            <div className="group-100">
              <div className="overlap-40">
                <footer className="footer-8">
                  <div className="overlap-group-22">
                    <img className="line-22" alt="Line" src="/img/line-7-1.svg" />
                    <div className="div-49">
                      <div className="p-8">
                        <p className="element-8">
                          <span className="text-wrapper-159">រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាង</span>
                          <span className="text-wrapper-160"> © 2023</span>
                          <span className="text-wrapper-159"> សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត</span>
                        </p>
                      </div>
                      <div className="ul-8">
                        <div className="frame-112">
                          <div className="frame-113">
                            <div className="text-wrapper-161">ព័ត៌មាន</div>
                            <div className="text-wrapper-162">សេចក្តីប្រកាស</div>
                            <div className="text-wrapper-163">សមិទ្ធិផល</div>
                            <div className="text-wrapper-164">ទំនាក់ទំនង</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
                <div className="frame-114">
                  <div className="frame-115">
                    <div className="frame-116">
                      <div className="frame-117">
                        <div className="group-101" />
                        <p className="div-50">
                          <span className="text-wrapper-165">
                            បណ្ណាល័យរូបភាព
                            <br />
                          </span>
                          <span className="text-wrapper-165">
                            <br />
                          </span>
                          <span className="text-wrapper-165">{""}</span>
                        </p>
                        <div className="frame-118">
                          <div className="frame-119">
                            <img className="image-32" alt="Image" src="/img/image-14-2x.png" />
                            <img className="image-32" alt="Image" src="/img/image-14-2x.png" />
                            <img className="image-32" alt="Image" src="/img/image-14-2x.png" />
                          </div>
                          <div className="frame-120">
                            <img className="image-33" alt="Image" src="/img/image-11.png" />
                            <img className="image-34" alt="Image" src="/img/image-15-2x.png" />
                            <img className="image-34" alt="Image" src="/img/image-15-2x.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="group-102">
                      <div className="group-103">
                        <div className="frame-121">
                          <div className="frame-122">
                            <div className="text-wrapper-166">អាសយដ្ឋាន</div>
                            <p className="text-wrapper-167">
                              អគារលេខ SD001 ផ្លួវ ២០០៤ ភូមិ តាងួន ១ សង្កាត់ កាកាប ១ ខណ្ឌពោធិ៍សែនជ័យ 120912
                              រាជធានីភ្នំពេញ
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="frame-123">
                        <p className="div-51">
                          <span className="text-wrapper-165">សារអេឡិចត្រូនិច</span>
                          <span className="text-wrapper-168">
                            {" "}
                            <br />
                          </span>
                          <span className="text-wrapper-168">
                            <br />
                          </span>
                          <span className="text-wrapper-168">{""}</span>
                        </p>
                        <div className="text-wrapper-169">info@samdechhunmanet.gov.kh</div>
                      </div>
                    </div>
                    <div className="div-footer-social-8">
                      <div className="text-wrapper-170">បណ្តាញសង្គម</div>
                      <div className="frame-124">
                        <img className="group-104" alt="Group" src="/img/group-6.png" />
                        <img className="group-104" alt="Group" src="/img/group-6.png" />
                        <img className="group-104" alt="Group" src="/img/group-6.png" />
                        <img className="group-104" alt="Group" src="/img/group-6.png" />
                        <div className="list-item-link-SVG-8">
                          <div className="symbol-41"></div>
                        </div>
                        <div className="list-item-link-SVG-8">
                          <img className="telegram-app-9" alt="Telegram app" src="/img/telegram-app-2x.png" />
                        </div>
                        <div className="symbol-42"></div>
                        <div className="symbol-43"></div>
                        <div className="symbol-44"></div>
                        <div className="symbol-45"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="subtract-8" alt="Subtract" src="/img/subtract-2.png" />
          </div>
        </div>
        <img className="button-SVG-3" alt="Button SVG" src="/img/button-svg.svg" />
        <div className="frame-125">
          <div className="frame-126">
            <div className="link-3">
              <p className="heading-president-6">“សន្តិភាព ថ្ងៃនេះ ជា សន្តិភាពរួម របស់ ប្រជាជាតិខ្មែរទាំងមូល”</p>
              <div className="text-wrapper-171">១១ តុលា ២០២៣</div>
              <div className="text-wrapper-172">នាយករដ្ឋមន្ត្រី ហ៊ុន ម៉ាណែត</div>
              <img className="line-23" alt="Line" src="/img/line-8.svg" />
              <div className="div-image-6" />
            </div>
          </div>
          <div className="frame-126">
            <div className="link-3">
              <div className="heading-president-7">“ត្រឹមមានឆន្ទៈមិនគ្រប់គ្រាន់ទេ បើយើងមិនធ្វើ!”</div>
              <div className="text-wrapper-171">១១ តុលា ២០២៣</div>
              <div className="text-wrapper-172">នាយករដ្ឋមន្ត្រី ហ៊ុន ម៉ាណែត</div>
              <img className="line-23" alt="Line" src="/img/line-8.svg" />
              <div className="div-image-7" />
            </div>
          </div>
          <div className="frame-126">
            <div className="link-3">
              <p className="heading-president-6">“សន្តិភាព ថ្ងៃនេះ ជា សន្តិភាពរួម របស់ ប្រជាជាតិខ្មែរទាំងមូល”</p>
              <div className="text-wrapper-171">១១ តុលា ២០២៣</div>
              <div className="text-wrapper-172">នាយករដ្ឋមន្ត្រី ហ៊ុន ម៉ាណែត</div>
              <img className="line-23" alt="Line" src="/img/line-8.svg" />
              <div className="div-image-8" />
            </div>
          </div>
          <div className="frame-126">
            <div className="link-3">
              <div className="heading-president-7">“ត្រឹមមានឆន្ទៈមិនគ្រប់គ្រាន់ទេ បើយើងមិនធ្វើ!”</div>
              <div className="text-wrapper-171">១១ តុលា ២០២៣</div>
              <div className="text-wrapper-172">នាយករដ្ឋមន្ត្រី ហ៊ុន ម៉ាណែត</div>
              <img className="line-23" alt="Line" src="/img/line-8.svg" />
              <div className="div-image-9" />
            </div>
          </div>
        </div>
        <div className="main-section-3">
          <div className="overlap-41">
            <div className="div-absolute-6" />
            <div className="heading-president-8">
              ត្រឹមមានឆន្ទ: មិនគ្រប់គ្រាន់ទេបើយើងមិនធ្វើ!|
              <br />
              សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត
            </div>
            <img className="screenshot-12" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-2.gif" />
          </div>
        </div>
      </div>
    </div>
  );
};
