import React from "react";
import { Link } from "react-router-dom";
import { ComponentVariant } from "../../components/ComponentVariant";
import { Group } from "../../components/Group";
import "./style.css";

export const VisionScreen = () => {
  return (
    <div className="vision-screen">
      <div className="vision-2">
        <div className="overlap-30">
          <div className="group-71">
            <div className="group-72" />
            <img className="background-8" alt="Background" src="/img/background1-6-1.png" />
          </div>
          <div className="group-73">
            <div className="overlap-31">
              <img className="cambodianflag-6" alt="Cambodianflag" src="/img/cambodianflag-design1-3-1.png" />
              <div className="group-74">
                <div className="overlap-32">
                  <footer className="footer-6">
                    <div className="overlap-group-17">
                      <img className="line-16" alt="Line" src="/img/line-7-7.svg" />
                      <div className="div-39">
                        <div className="p-6">
                          <p className="element-6">
                            <span className="text-wrapper-123">រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាង</span>
                            <span className="text-wrapper-124"> © 2023</span>
                            <span className="text-wrapper-123"> សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត</span>
                          </p>
                        </div>
                        <div className="ul-6">
                          <div className="frame-85">
                            <div className="frame-86">
                              <div className="text-wrapper-125">ព័ត៌មាន</div>
                              <div className="text-wrapper-126">សេចក្តីប្រកាស</div>
                              <div className="text-wrapper-127">សមិទ្ធិផល</div>
                              <div className="text-wrapper-128">ទំនាក់ទំនង</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </footer>
                  <div className="frame-87">
                    <div className="frame-88">
                      <div className="frame-89">
                        <div className="frame-90">
                          <div className="group-75" />
                          <p className="div-40">
                            <span className="text-wrapper-129">
                              បណ្ណាល័យរូបភាព
                              <br />
                            </span>
                            <span className="text-wrapper-129">
                              <br />
                            </span>
                            <span className="text-wrapper-129">{""}</span>
                          </p>
                          <div className="frame-91">
                            <div className="frame-93">
                              <img className="image-26" alt="Image" src="/img/image-14.png" />
                              <img className="image-26" alt="Image" src="/img/image-14.png" />
                              <img className="image-26" alt="Image" src="/img/image-14.png" />
                            </div>
                            <div className="frame-94">
                              <img className="image-27" alt="Image" src="/img/image-11-2x.png" />
                              <img className="image-28" alt="Image" src="/img/image-15.png" />
                              <img className="image-28" alt="Image" src="/img/image-15.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="group-76">
                        <div className="group-77">
                          <div className="frame-95">
                            <div className="frame-96">
                              <div className="text-wrapper-130">អាសយដ្ឋាន</div>
                              <p className="text-wrapper-131">
                                អគារលេខ SD001 ផ្លួវ ២០០៤ ភូមិ តាងួន ១ សង្កាត់ កាកាប ១ ខណ្ឌពោធិ៍សែនជ័យ 120912
                                រាជធានីភ្នំពេញ
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="frame-97">
                          <p className="div-41">
                            <span className="text-wrapper-129">សារអេឡិចត្រូនិច</span>
                            <span className="text-wrapper-132">
                              {" "}
                              <br />
                            </span>
                            <span className="text-wrapper-132">
                              <br />
                            </span>
                            <span className="text-wrapper-132">{""}</span>
                          </p>
                          <div className="text-wrapper-133">info@samdechhunmanet.gov.kh</div>
                        </div>
                      </div>
                      <div className="div-footer-social-6">
                        <div className="text-wrapper-134">បណ្តាញសង្គម</div>
                        <div className="frame-98">
                          <img className="group-78" alt="Group" src="/img/group-36.png" />
                          <img className="group-78" alt="Group" src="/img/group-36.png" />
                          <img className="group-78" alt="Group" src="/img/group-36.png" />
                          <img className="group-78" alt="Group" src="/img/group-36.png" />
                          <div className="list-item-link-SVG-6">
                            <div className="symbol-31"></div>
                          </div>
                          <div className="list-item-link-SVG-6">
                            <img className="telegram-app-7" alt="Telegram app" src="/img/telegram-app.png" />
                          </div>
                          <div className="symbol-32"></div>
                          <div className="symbol-33"></div>
                          <div className="symbol-34"></div>
                          <div className="symbol-35"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="subtract-6" alt="Subtract" src="/img/subtract-1.png" />
            </div>
          </div>
          <ComponentVariant className="component-1-variant2" frameClassName="component-variant-instance" />
          <img className="div-42" alt="Div" src="/img/div.png" />
          <div className="pentagon-strategy">PENTAGON STRATEGY OVERVIEW</div>
        </div>
        <div className="main-section-2">
          <div className="overlap-group-18">
            <div className="div-absolute-5" />
            <div className="heading-president-5">
              ត្រឹមមានឆន្ទ: មិនគ្រប់គ្រាន់ទេបើយើងមិនធ្វើ!|
              <br />
              សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត
            </div>
            <img className="screenshot-11" alt="Screenshot" />
          </div>
        </div>
        <div className="group-79">
          <button className="button-agenda">PENTAGON STRATEGY</button>
          <Link className="button-statements-2" to="/vision1">
            QUOTES
          </Link>
        </div>
        <div className="group-80">
          <div className="overlap-33">
            <img className="line-17" alt="Line" src="/img/line-11.svg" />
            <img className="line-18" alt="Line" src="/img/line-12.svg" />
          </div>
        </div>
        <Group className="group-81" to="/home" to1="/biography-1" to2="/vision" to3="/contact" />
        <img className="pantagon" alt="Pantagon" src="/img/pantagon1-1-2.png" />
        <img className="button-SVG-2" alt="Button SVG" src="/img/button-svg-1.svg" />
      </div>
    </div>
  );
};
