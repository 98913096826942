/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const ComponentWrapper = ({
  className,
  frameClassName,
  svg = "/img/svg.svg",
  img = "/img/svg-1.svg",
  svg1 = "/img/svg-2.svg",
  to,
}) => {
  return (
    <div className={`component-wrapper ${className}`}>
      <div className={`frame-5 ${frameClassName}`}>
        <Link className="frame-6" to={to}>
          <div className="img-wrapper">
            <img className="image-2" alt="Image" src="/img/image-13.png" />
          </div>
          <div className="text-wrapper-9">2 min read</div>
          <img className="svg" alt="Svg" src={svg} />
          <div className="text-wrapper-10">12th Feb 2023</div>
          <p className="text-wrapper-11">
            Letter of Greetings to Samdech Krala Hom Sar Kheng, Deputy Prime Minister and Minister of Interior
          </p>
        </Link>
        <div className="frame-6">
          <div className="img-wrapper">
            <img className="image-2" alt="Image" src="/img/image-13.png" />
          </div>
          <div className="text-wrapper-9">2 min read</div>
          <img className="svg" alt="Svg" src={img} />
          <div className="text-wrapper-10">12th Feb 2023</div>
          <p className="text-wrapper-11">
            Letter of Greetings to Samdech Krala Hom Sar Kheng, Deputy Prime Minister and Minister of Interior
          </p>
        </div>
        <div className="frame-6">
          <div className="img-wrapper">
            <img className="image-2" alt="Image" src="/img/image-13.png" />
          </div>
          <div className="text-wrapper-9">2 min read</div>
          <img className="svg" alt="Svg" src={svg1} />
          <div className="text-wrapper-10">12th Feb 2023</div>
          <p className="text-wrapper-11">
            Letter of Greetings to Samdech Krala Hom Sar Kheng, Deputy Prime Minister and Minister of Interior
          </p>
        </div>
      </div>
    </div>
  );
};

ComponentWrapper.propTypes = {
  svg: PropTypes.string,
  img: PropTypes.string,
  svg1: PropTypes.string,
  to: PropTypes.string,
};
