/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const GroupWrapper = ({
  className,
  img = "/img/img.svg",
  img1 = "/img/img.svg",
  img2 = "/img/img.svg",
  img3 = "/img/img.svg",
}) => {
  return (
    <div className={`group-wrapper ${className}`}>
      <div className="frame-8">
        <div className="button-go-to">
          <div className="symbol-2">&lt;</div>
        </div>
        <div className="div-relative-wrapper">
          <div className="div-relative">
            <img className="img-2" alt="Img" src={img} />
            <div className="text-wrapper-21">6th April, 2023</div>
            <div className="image-4">
              <img className="image-5" alt="Image" src="/img/image-13-1.png" />
            </div>
            <img className="vector-2" alt="Vector" src="/img/vector-11.svg" />
          </div>
        </div>
        <div className="div-relative-wrapper">
          <div className="div-relative">
            <img className="img-2" alt="Img" src={img1} />
            <div className="text-wrapper-21">6th April, 2023</div>
            <div className="image-4">
              <img className="image-5" alt="Image" src="/img/image-13-1.png" />
            </div>
            <img className="vector-2" alt="Vector" src="/img/vector-11.svg" />
          </div>
        </div>
        <div className="div-relative-wrapper">
          <div className="div-relative">
            <img className="img-2" alt="Img" src={img2} />
            <div className="text-wrapper-21">6th April, 2023</div>
            <div className="image-4">
              <img className="image-5" alt="Image" src="/img/image-13-1.png" />
            </div>
            <img className="vector-2" alt="Vector" src="/img/vector-11.svg" />
          </div>
        </div>
        <div className="div-relative-wrapper">
          <div className="div-relative">
            <img className="img-2" alt="Img" src={img3} />
            <div className="text-wrapper-21">6th April, 2023</div>
            <div className="image-4">
              <img className="image-5" alt="Image" src="/img/image-13-1.png" />
            </div>
            <img className="vector-2" alt="Vector" src="/img/vector-11.svg" />
          </div>
        </div>
        <div className="button-go-to-2">
          <div className="symbol-3">&lt;</div>
        </div>
      </div>
    </div>
  );
};

GroupWrapper.propTypes = {
  img: PropTypes.string,
  img1: PropTypes.string,
  img2: PropTypes.string,
  img3: PropTypes.string,
};
