/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const MsftContentCard = ({ className, vector = "/img/vector-24.svg", img = "/img/vector-25.svg" }) => {
  return (
    <div className={`msft-content-card ${className}`}>
      <p className="text-wrapper-23">Children of Takeo are seeking shelters</p>
      <div className="text-wrapper-24">2nd Mar 2023</div>
      <img className="image-6" alt="Image" src="/img/image-10-1.png" />
      <img className="vector-3" alt="Vector" src={vector} />
      <img className="vector-4" alt="Vector" src={img} />
    </div>
  );
};

MsftContentCard.propTypes = {
  vector: PropTypes.string,
  img: PropTypes.string,
};
