import React from "react";
import { Group } from "../../components/Group";
import "./style.css";

export const BiographyScreen = () => {
  return (
    <div className="biography-screen">
      <div className="biography-2">
        <div className="overlap-46">
          <div className="group-118" />
          <img className="background-10" alt="Background" src="/img/background1-7.png" />
          <div className="group-119">
            <div className="group-120">
              <img className="award-2" alt="Award" src="/img/award-01-1.png" />
              <div className="group-121">
                <p className="text-wrapper-185">
                  Recognized as a successful individual, 64th by the Exchange Study Program “ Likuanu ” and was awarded
                  the Singapore Recognition Award based on the record of the work that has been done. And has a dominant
                  potential to participate in its national development As well as in bilateral relations work with the
                  Republic of Singapore
                </p>
                <div className="text-wrapper-186">Singapore Recognition Award</div>
              </div>
            </div>
            <div className="group-122">
              <img className="award-3" alt="Award" src="/img/award-01-2.jpg" />
              <div className="group-123">
                <p className="text-wrapper-185">
                  Recognized as a successful individual, 64th by the Exchange Study Program “ Likuanu ” and was awarded
                  the Singapore Recognition Award based on the record of the work that has been done. And has a dominant
                  potential to participate in its national development As well as in bilateral relations work with the
                  Republic of Singapore
                </p>
                <div className="text-wrapper-187">Singapore Recognition Award</div>
              </div>
            </div>
            <img className="group-124" alt="Group" src="/img/group-5-2.jpg" />
          </div>
          <img className="div-56" alt="Div" src="/img/div-6.png" />
        </div>
        <div className="overlap-47">
          <img className="un-3" alt="Un" src="/img/un71003324-1-1-2.png" />
          <img className="pseudo-2" alt="Pseudo" src="/img/pseudo-1.svg" />
          <img className="div-57" alt="Div" src="/img/div-5.png" />
        </div>
        <div className="group-125">
          <div className="frame-140">
            <div className="overlap-group-25">
              <div className="frame-141">
                <p className="text-wrapper-188">
                  He is committed to the cause of the RCAF and the people of Cambodia. His courage and endurance are
                  tremendous, allowing him to endure great hardships. He is disciplined when it comes to following
                  orders and setting an example.
                </p>
                <p className="text-wrapper-189">
                  Chief Commanding Officer Hun Manet has had an impressive career in the RCAF. He is a recipient of
                  various awards and commendations, including honorary ranks and the Medal of Honor. His influence and
                  impact on the RCAF has been widespread and he is respected by his peers and subordinates alike. As a
                  leader and commander, he has given the RCAF immense pride and dignity.
                </p>
                <p className="text-wrapper-190">
                  Chief Commanding Officer Hun Manet has had an impressive career in the RCAF. He is a recipient of
                  various awards and commendations, including honorary ranks and the Medal of Honor. His influence and
                  impact on the RCAF has been widespread and he is respected by his peers and subordinates alike. As a
                  leader and commander, he has given the RCAF immense pride and dignity.
                </p>
              </div>
            </div>
          </div>
          <div className="group-126">
            <div className="text-wrapper-191">Hun Manet</div>
            <p className="text-wrapper-192">
              Chief Commanding Officer Hun Manet is a prominent figure in the Royal Cambodian Armed Forces (RCAF)
            </p>
            <img
              className="hrfihu-cambodia-pm"
              alt="Cambodia pm"
              src="/img/hrfi1hu-cambodia-pm-afp-625x300-22-august-23-removebg-preview-1-2.png"
            />
          </div>
        </div>
        <div className="group-127">
          <img className="div-58" alt="Div" src="/img/div-7.png" />
          <img className="medal-2" alt="Medal" src="/img/medal-02-1.png" />
        </div>
        <div className="group-128">
          <div className="overlap-48">
            <img className="cambodianflag-10" alt="Cambodianflag" src="/img/cambodianflag-design1-3-2.png" />
            <div className="group-129">
              <div className="overlap-49">
                <footer className="footer-10">
                  <div className="overlap-group-26">
                    <img className="line-27" alt="Line" src="/img/line-7-8.svg" />
                    <div className="div-59">
                      <div className="p-10">
                        <p className="element-10">
                          <span className="text-wrapper-193">រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាង</span>
                          <span className="text-wrapper-194"> © 2023</span>
                          <span className="text-wrapper-193"> សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត</span>
                        </p>
                      </div>
                      <div className="ul-10">
                        <div className="frame-142">
                          <div className="frame-143">
                            <div className="text-wrapper-195">ព័ត៌មាន</div>
                            <div className="text-wrapper-196">សេចក្តីប្រកាស</div>
                            <div className="text-wrapper-197">សមិទ្ធិផល</div>
                            <div className="text-wrapper-198">ទំនាក់ទំនង</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
                <div className="frame-144">
                  <div className="frame-145">
                    <div className="frame-146">
                      <div className="frame-147">
                        <div className="group-131" />
                        <p className="div-60">
                          <span className="text-wrapper-199">
                            បណ្ណាល័យរូបភាព
                            <br />
                          </span>
                          <span className="text-wrapper-199">
                            <br />
                          </span>
                          <span className="text-wrapper-199">{""}</span>
                        </p>
                        <div className="frame-148">
                          <div className="frame-149">
                            <img className="image-38" alt="Image" src="/img/image-14-2x.png" />
                            <img className="image-38" alt="Image" src="/img/image-14-2x.png" />
                            <img className="image-38" alt="Image" src="/img/image-14-2x.png" />
                          </div>
                          <div className="frame-150">
                            <img className="image-39" alt="Image" src="/img/image-11.png" />
                            <img className="image-40" alt="Image" src="/img/image-15-2x.png" />
                            <img className="image-40" alt="Image" src="/img/image-15-2x.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="group-132">
                      <div className="group-133">
                        <div className="frame-151">
                          <div className="frame-152">
                            <div className="text-wrapper-200">អាសយដ្ឋាន</div>
                            <p className="text-wrapper-201">
                              អគារលេខ SD001 ផ្លួវ ២០០៤ ភូមិ តាងួន ១ សង្កាត់ កាកាប ១ ខណ្ឌពោធិ៍សែនជ័យ 120912
                              រាជធានីភ្នំពេញ
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="frame-153">
                        <p className="div-61">
                          <span className="text-wrapper-199">សារអេឡិចត្រូនិច</span>
                          <span className="text-wrapper-202">
                            {" "}
                            <br />
                          </span>
                          <span className="text-wrapper-202">
                            <br />
                          </span>
                          <span className="text-wrapper-202">{""}</span>
                        </p>
                        <div className="text-wrapper-203">info@samdechhunmanet.gov.kh</div>
                      </div>
                    </div>
                    <div className="div-footer-social-10">
                      <div className="text-wrapper-204">បណ្តាញសង្គម</div>
                      <div className="frame-154">
                        <img className="group-134" alt="Group" src="/img/group-2x.png" />
                        <img className="group-134" alt="Group" src="/img/group-2x.png" />
                        <img className="group-134" alt="Group" src="/img/group-2x.png" />
                        <img className="group-134" alt="Group" src="/img/group-2x.png" />
                        <div className="list-item-link-SVG-10">
                          <div className="symbol-51"></div>
                        </div>
                        <div className="list-item-link-SVG-10">
                          <img className="telegram-app-11" alt="Telegram app" src="/img/telegram-app-2x.png" />
                        </div>
                        <div className="symbol-52"></div>
                        <div className="symbol-53"></div>
                        <div className="symbol-54"></div>
                        <div className="symbol-55"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="subtract-10" alt="Subtract" />
          </div>
        </div>
        <Group
          assetHero="/img/asset-1new-hero-2-2x.png"
          className="group-135"
          imageClassName="group-136"
          linkImageClassName="group-137"
          to="/home"
          to1="/biography-1"
          to2="/vision"
          to3="/contact"
        />
      </div>
    </div>
  );
};
