import React from "react";
import { Group } from "../../components/Group";
import "./style.css";

export const Biography = () => {
  return (
    <div className="biography">
      <div className="div-18">
        <div className="overlap-16">
          <img className="background-4" alt="Background" src="/img/background1-6-1.png" />
          <img className="background-5" alt="Background" src="/img/background1-8.png" />
          <img className="background-6" alt="Background" src="/img/background1-8.png" />
          <div className="fluent-card-wrapper">
            <div className="rectangle-wrapper">
              <div className="rectangle-3" />
            </div>
          </div>
          <img className="un" alt="Un" src="/img/un71003324-1-1.png" />
          <img className="pseudo" alt="Pseudo" src="/img/pseudo.svg" />
          <img className="div-19" alt="Div" src="/img/div.png" />
          <div className="cambodia-prime">CAMBODIA PRIME MINISTER</div>
          <div className="group-40">
            <div className="award-wrapper">
              <img className="award" alt="Award" src="/img/award-01-1.png" />
            </div>
            <div className="group-41">
              <p className="text-wrapper-64">
                Recognized as a successful individual, 64th by the Exchange Study Program “ Likuanu ” and was awarded
                the Singapore Recognition Award based on the record of the work that has been done. And has a dominant
                potential to participate in its national development As well as in bilateral relations work with the
                Republic of Singapore
              </p>
              <div className="text-wrapper-65">Singapore Recognition Award</div>
            </div>
          </div>
          <div className="group-42">
            <div className="overlap-17">
              <img className="award" alt="Award" src="/img/award-01-2-2x.jpg" />
            </div>
            <div className="group-43">
              <p className="text-wrapper-64">
                Recognized as a successful individual, 64th by the Exchange Study Program “ Likuanu ” and was awarded
                the Singapore Recognition Award based on the record of the work that has been done. And has a dominant
                potential to participate in its national development As well as in bilateral relations work with the
                Republic of Singapore
              </p>
              <div className="text-wrapper-66">Singapore Recognition Award</div>
            </div>
          </div>
          <img className="group-44" alt="Group" src="/img/group-5-1.jpg" />
          <div className="group-45">
            <div className="overlap-18">
              <div className="text-wrapper-67">AWARDS AND MEDALS</div>
            </div>
            <img className="medal" alt="Medal" src="/img/medal-02-1.png" />
          </div>
          <div className="group-46">
            <div className="overlap-19">
              <img className="cambodianflag-3" alt="Cambodianflag" src="/img/cambodianflag-design1-3-1.png" />
              <div className="group-47">
                <div className="overlap-20">
                  <footer className="footer-3">
                    <div className="overlap-group-12">
                      <img className="line-12" alt="Line" src="/img/line-7-3.svg" />
                      <div className="div-20">
                        <div className="p-3">
                          <p className="element-3">
                            <span className="text-wrapper-68">រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាង</span>
                            <span className="text-wrapper-69"> © 2023</span>
                            <span className="text-wrapper-68"> សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត</span>
                          </p>
                        </div>
                        <div className="ul-3">
                          <div className="frame-43">
                            <div className="frame-44">
                              <div className="text-wrapper-70">ព័ត៌មាន</div>
                              <div className="text-wrapper-71">សេចក្តីប្រកាស</div>
                              <div className="text-wrapper-72">សមិទ្ធិផល</div>
                              <div className="text-wrapper-73">ទំនាក់ទំនង</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </footer>
                  <div className="frame-45">
                    <div className="frame-46">
                      <div className="frame-47">
                        <div className="frame-48">
                          <div className="group-48" />
                          <p className="div-21">
                            <span className="text-wrapper-74">
                              បណ្ណាល័យរូបភាព
                              <br />
                            </span>
                            <span className="text-wrapper-74">
                              <br />
                            </span>
                            <span className="text-wrapper-74">{""}</span>
                          </p>
                          <div className="frame-49">
                            <div className="frame-50">
                              <img className="image-15" alt="Image" src="/img/image-14.png" />
                              <img className="image-15" alt="Image" src="/img/image-14.png" />
                              <img className="image-15" alt="Image" src="/img/image-14.png" />
                            </div>
                            <div className="frame-51">
                              <img className="image-16" alt="Image" src="/img/image-11-2x.png" />
                              <img className="image-17" alt="Image" src="/img/image-15.png" />
                              <img className="image-17" alt="Image" src="/img/image-15.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="group-49">
                        <div className="group-50">
                          <div className="frame-52">
                            <div className="frame-53">
                              <div className="text-wrapper-75">អាសយដ្ឋាន</div>
                              <p className="text-wrapper-76">
                                អគារលេខ SD001 ផ្លួវ ២០០៤ ភូមិ តាងួន ១ សង្កាត់ កាកាប ១ ខណ្ឌពោធិ៍សែនជ័យ 120912
                                រាជធានីភ្នំពេញ
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="frame-54">
                          <p className="div-22">
                            <span className="text-wrapper-74">សារអេឡិចត្រូនិច</span>
                            <span className="text-wrapper-77">
                              {" "}
                              <br />
                            </span>
                            <span className="text-wrapper-77">
                              <br />
                            </span>
                            <span className="text-wrapper-77">{""}</span>
                          </p>
                          <div className="text-wrapper-78">info@samdechhunmanet.gov.kh</div>
                        </div>
                      </div>
                      <div className="div-footer-social-3">
                        <div className="text-wrapper-79">បណ្តាញសង្គម</div>
                        <div className="frame-55">
                          <img className="group-51" alt="Group" src="/img/group-18.png" />
                          <img className="group-51" alt="Group" src="/img/group-18.png" />
                          <img className="group-51" alt="Group" src="/img/group-18.png" />
                          <img className="group-51" alt="Group" src="/img/group-18.png" />
                          <div className="list-item-link-SVG-3">
                            <div className="symbol-16"></div>
                          </div>
                          <div className="list-item-link-SVG-3">
                            <img className="telegram-app-4" alt="Telegram app" src="/img/telegram-app.png" />
                          </div>
                          <div className="symbol-17"></div>
                          <div className="symbol-18"></div>
                          <div className="symbol-19"></div>
                          <div className="symbol-20"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="subtract-3" alt="Subtract" src="/img/subtract-1.png" />
            </div>
          </div>
          <div className="group-52">
            <div className="frame-56">
              <div className="overlap-group-13">
                <div className="frame-57">
                  <p className="text-wrapper-80">
                    He is committed to the cause of the RCAF and the people of Cambodia. His courage and endurance are
                    tremendous, allowing him to endure great hardships. He is disciplined when it comes to following
                    orders and setting an example.
                  </p>
                  <p className="text-wrapper-81">
                    Chief Commanding Officer Hun Manet has had an impressive career in the RCAF. He is a recipient of
                    various awards and commendations, including honorary ranks and the Medal of Honor. His influence and
                    impact on the RCAF has been widespread and he is respected by his peers and subordinates alike. As a
                    leader and commander, he has given the RCAF immense pride and dignity.
                  </p>
                  <p className="text-wrapper-82">
                    Chief Commanding Officer Hun Manet has had an impressive career in the RCAF. He is a recipient of
                    various awards and commendations, including honorary ranks and the Medal of Honor. His influence and
                    impact on the RCAF has been widespread and he is respected by his peers and subordinates alike. As a
                    leader and commander, he has given the RCAF immense pride and dignity.
                  </p>
                </div>
              </div>
            </div>
            <div className="group-53">
              <div className="text-wrapper-83">Hun Manet</div>
              <p className="text-wrapper-84">
                Chief Commanding Officer Hun Manet is a prominent figure in the Royal Cambodian Armed Forces (RCAF)
              </p>
              <img
                className="cambodia-pm"
                alt="Cambodia pm"
                src="/img/hrfi1hu-cambodia-pm-afp-625x300-22-august-23-removebg-preview-1.png"
              />
            </div>
          </div>
        </div>
        <Group className="group-15-instance" to="/home" to1="/biography-1" to2="/vision" to3="/contact" />
      </div>
    </div>
  );
};
