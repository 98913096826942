/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const CalendarAlt1 = ({ className }) => {
  return (
    <svg
      className={`calendar-alt-1 ${className}`}
      fill="none"
      height="42"
      viewBox="0 0 41 42"
      width="41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M15.375 5.62484C15.375 4.68135 14.6102 3.9165 13.6667 3.9165C12.7232 3.9165 11.9583 4.68135 11.9583 5.62484H10.25C7.41954 5.62484 5.125 7.91938 5.125 10.7498V32.9582C5.125 35.7886 7.41954 38.0832 10.25 38.0832H30.75C33.5805 38.0832 35.875 35.7886 35.875 32.9582V10.7498C35.875 7.91938 33.5805 5.62484 30.75 5.62484H29.0417C29.0417 4.68135 28.2768 3.9165 27.3333 3.9165C26.3898 3.9165 25.625 4.68135 25.625 5.62484H15.375ZM32.4583 12.4582V10.7498C32.4583 9.80635 31.6935 9.0415 30.75 9.0415H29.0417C29.0417 9.98499 28.2768 10.7498 27.3333 10.7498C26.3898 10.7498 25.625 9.98499 25.625 9.0415H15.375C15.375 9.98499 14.6102 10.7498 13.6667 10.7498C12.7232 10.7498 11.9583 9.98499 11.9583 9.0415H10.25C9.30651 9.0415 8.54167 9.80635 8.54167 10.7498V12.4582H32.4583ZM8.54167 15.8748V32.9582C8.54167 33.9017 9.30651 34.6665 10.25 34.6665H30.75C31.6935 34.6665 32.4583 33.9017 32.4583 32.9582V15.8748H8.54167Z"
        fill="#253B95"
        fillRule="evenodd"
      />
    </svg>
  );
};
