import React from "react";
import { Link } from "react-router-dom";
import { ComponentVariant } from "../../components/ComponentVariant";
import { Group } from "../../components/Group";
import "./style.css";

export const Screen8 = () => {
  return (
    <div className="screen-8">
      <div className="vision-4">
        <div className="overlap-42">
          <div className="group-105">
            <div className="group-106" />
            <img className="background-9" alt="Background" src="/img/background1-6-2-2.png" />
          </div>
          <div className="group-107">
            <div className="overlap-43">
              <img className="cambodianflag-9" alt="Cambodianflag" src="/img/cambodianflag-design1-3-2.png" />
              <div className="group-108">
                <div className="overlap-44">
                  <footer className="footer-9">
                    <div className="overlap-group-23">
                      <img className="line-24" alt="Line" src="/img/line-7-7.svg" />
                      <div className="div-52">
                        <div className="p-9">
                          <p className="element-9">
                            <span className="text-wrapper-173">រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាង</span>
                            <span className="text-wrapper-174"> © 2023</span>
                            <span className="text-wrapper-173"> សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត</span>
                          </p>
                        </div>
                        <div className="ul-9">
                          <div className="frame-127">
                            <div className="frame-128">
                              <div className="text-wrapper-175">ព័ត៌មាន</div>
                              <div className="text-wrapper-176">សេចក្តីប្រកាស</div>
                              <div className="text-wrapper-177">សមិទ្ធិផល</div>
                              <div className="text-wrapper-178">ទំនាក់ទំនង</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </footer>
                  <div className="frame-129">
                    <div className="frame-130">
                      <div className="frame-131">
                        <div className="frame-132">
                          <div className="group-109" />
                          <p className="div-53">
                            <span className="text-wrapper-179">
                              បណ្ណាល័យរូបភាព
                              <br />
                            </span>
                            <span className="text-wrapper-179">
                              <br />
                            </span>
                            <span className="text-wrapper-179">{""}</span>
                          </p>
                          <div className="frame-133">
                            <div className="frame-134">
                              <img className="image-35" alt="Image" src="/img/image-14-2x.png" />
                              <img className="image-35" alt="Image" src="/img/image-14-2x.png" />
                              <img className="image-35" alt="Image" src="/img/image-14-2x.png" />
                            </div>
                            <div className="frame-135">
                              <img className="image-36" alt="Image" src="/img/image-11.png" />
                              <img className="image-37" alt="Image" src="/img/image-15-2x.png" />
                              <img className="image-37" alt="Image" src="/img/image-15-2x.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="group-110">
                        <div className="group-111">
                          <div className="frame-136">
                            <div className="frame-137">
                              <div className="text-wrapper-180">អាសយដ្ឋាន</div>
                              <p className="text-wrapper-181">
                                អគារលេខ SD001 ផ្លួវ ២០០៤ ភូមិ តាងួន ១ សង្កាត់ កាកាប ១ ខណ្ឌពោធិ៍សែនជ័យ 120912
                                រាជធានីភ្នំពេញ
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="frame-138">
                          <p className="div-54">
                            <span className="text-wrapper-179">សារអេឡិចត្រូនិច</span>
                            <span className="text-wrapper-182">
                              {" "}
                              <br />
                            </span>
                            <span className="text-wrapper-182">
                              <br />
                            </span>
                            <span className="text-wrapper-182">{""}</span>
                          </p>
                          <div className="text-wrapper-183">info@samdechhunmanet.gov.kh</div>
                        </div>
                      </div>
                      <div className="div-footer-social-9">
                        <div className="text-wrapper-184">បណ្តាញសង្គម</div>
                        <div className="frame-139">
                          <img className="group-112" alt="Group" src="/img/group-42.png" />
                          <img className="group-112" alt="Group" src="/img/group-42.png" />
                          <img className="group-112" alt="Group" src="/img/group-42.png" />
                          <img className="group-112" alt="Group" src="/img/group-42.png" />
                          <div className="list-item-link-SVG-9">
                            <div className="symbol-46"></div>
                          </div>
                          <div className="list-item-link-SVG-9">
                            <img className="telegram-app-10" alt="Telegram app" src="/img/telegram-app-2x.png" />
                          </div>
                          <div className="symbol-47"></div>
                          <div className="symbol-48"></div>
                          <div className="symbol-49"></div>
                          <div className="symbol-50"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="subtract-9" alt="Subtract" src="/img/subtract-2.png" />
            </div>
          </div>
          <ComponentVariant
            className="component-1-variant2-instance"
            divImage="/img/div-image-4-2x.png"
            divImage1="/img/div-image-4-2x.png"
            divImage2="/img/div-image-4-2x.png"
            frameClassName="component-3"
            img="/img/div-image-4-2x.png"
          />
          <img className="div-55" alt="Div" src="/img/div-2.png" />
          <div className="pentagon-strategy-2">PENTAGON STRATEGY OVERVIEW</div>
        </div>
        <div className="main-section-4">
          <div className="overlap-group-24">
            <div className="div-absolute-7" />
            <div className="heading-president-9">
              ត្រឹមមានឆន្ទ: មិនគ្រប់គ្រាន់ទេបើយើងមិនធ្វើ!|
              <br />
              សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត
            </div>
            <img className="screenshot-13" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-2.gif" />
          </div>
        </div>
        <div className="group-113">
          <button className="button-3">PENTAGON STRATEGY</button>
          <Link className="button-statements-4" to="/vision1">
            QUOTES
          </Link>
        </div>
        <div className="group-114">
          <div className="overlap-45">
            <img className="line-25" alt="Line" src="/img/line-11.svg" />
            <img className="line-26" alt="Line" src="/img/line-12.svg" />
          </div>
        </div>
        <Group
          assetHero="/img/asset-1new-hero-2-2x.png"
          className="group-115"
          imageClassName="group-116"
          linkImageClassName="group-117"
          to="/home"
          to1="/biography-1"
          to2="/vision"
          to3="/contact"
        />
        <img className="pantagon-2" alt="Pantagon" src="/img/pantagon1-1.png" />
        <img className="button-SVG-4" alt="Button SVG" src="/img/button-svg-1.svg" />
      </div>
    </div>
  );
};
