import React from "react";
import { Component } from "../../components/Component";
import { ComponentWrapper } from "../../components/ComponentWrapper";
import { Frame } from "../../components/Frame";
import { Group } from "../../components/Group";
import { CalendarAlt1 } from "../../icons/CalendarAlt1";
import "./style.css";

export const Home = () => {
  return (
    <div className="home">
      <div className="div-14">
        <div className="overlap-6">
          <div className="group-10">
            <div className="overlap-group-7">
              <img className="background" alt="Background" src="/img/background1-6-1.png" />
              <img className="background-2" alt="Background" src="/img/background1-6-1.png" />
            </div>
          </div>
          <img className="image-10" alt="Image" src="/img/image-2.png" />
          <div className="section">
            <div className="overlap-7">
              <div className="div-absolute-2" />
              <div className="div-absolute-3" />
              <div className="div-absolute-wrapper">
                <div className="div-absolute-4" />
              </div>
              <div className="group-11">
                <div className="overlap-8">
                  <div className="group-12">
                    <div className="text-wrapper-39">
                      អគ្គលេខាធិការអាស៊ាន ជួបជាមួយសម្តេចមហាបវរធិបតី
                      <br />
                      ហ៊ុន ម៉ាណែត នាយករដ្ឋមន្ត្រីព្រះរាជាណាចក្រកម្ពុជា
                    </div>
                    <div className="group-13">
                      <div className="group-14">
                        <div className="symbol-9"></div>
                        <img className="telegram-app-2" alt="Telegram app" src="/img/telegram-app-1.png" />
                        <div className="symbol-10"></div>
                      </div>
                      <div className="text-wrapper-40">១០ តុលា ២០២៣</div>
                    </div>
                  </div>
                  <div className="group-16">
                    <div className="group-17">
                      <div className="overlap-9">
                        <div className="group-18">
                          <div className="overlap-group-8">
                            <div className="text-wrapper-41">ព័​ត៍​មាន​បន្ថែម</div>
                            <div className="rectangle" />
                          </div>
                        </div>
                        <img className="arrow-2" alt="Arrow" src="/img/arrow-1.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-19">
                <div className="ellipse-2" />
                <div className="ellipse-3" />
                <div className="ellipse-4" />
                <div className="ellipse-5" />
                <div className="ellipse-6" />
              </div>
            </div>
            <Group className="group-instance" to="/home" to1="/biography-1" to2="/vision" to3="/contact" />
          </div>
          <div className="group-20">
            <p className="text-wrapper-42">ជួបជុំគ្នារវាងសម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត​ និង ក្មួយប្រុសភារម្យ...</p>
          </div>
          <div className="group-21">
            <div className="overlap-10">
              <div className="group-22">
                <div className="overlap-group-9">
                  <img className="image-11" alt="Image" src="/img/image-3.png" />
                  <div className="group-23" />
                </div>
              </div>
              <div className="text-wrapper-43">
                ព័ត៍មានសម្តេចធិបតី
                <br />
                នាបណ្តាខេត្ត
              </div>
              <div className="text-wrapper-44">ខេត្តបាត់ដំបង</div>
              <img className="asset-map" alt="Asset map" src="/img/asset-1cambodia-map-1.png" />
              <div className="group-24">
                <div className="group-25">
                  <p className="text-wrapper-42">
                    ជួបជុំគ្នារវាងសម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត​ និង ក្មួយប្រុសភារម្យ...
                  </p>
                </div>
                <div className="text-wrapper-45">១១ តុលា ២០២៣</div>
                <img className="line-6" alt="Line" src="/img/line-1-4.svg" />
              </div>
              <div className="group-26">
                <div className="group-25">
                  <p className="text-wrapper-42">
                    ជួបជុំគ្នារវាងសម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត​ និង ក្មួយប្រុសភារម្យ...
                  </p>
                </div>
                <div className="text-wrapper-45">១១ តុលា ២០២៣</div>
                <img className="line-6" alt="Line" src="/img/line-1-4.svg" />
              </div>
              <div className="group-27">
                <div className="group-25">
                  <p className="text-wrapper-42">
                    ជួបជុំគ្នារវាងសម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត​ និង ក្មួយប្រុសភារម្យ...
                  </p>
                </div>
                <div className="text-wrapper-45">១១ តុលា ២០២៣</div>
                <img className="line-6" alt="Line" src="/img/line-1-4.svg" />
              </div>
              <div className="group-28">
                <div className="group-25">
                  <p className="text-wrapper-42">
                    ជួបជុំគ្នារវាងសម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត​ និង ក្មួយប្រុសភារម្យ...
                  </p>
                </div>
                <div className="text-wrapper-45">១១ តុលា ២០២៣</div>
                <img className="line-6" alt="Line" src="/img/line-1-7.svg" />
              </div>
              <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-8.png" />
            </div>
          </div>
          <Component
            arrow="/img/arrow-3-4.svg"
            arrow1="/img/arrow-3-4.svg"
            arrow2="/img/arrow-3-4.svg"
            arrow3="/img/arrow-3-4.svg"
            className="component-1"
            frameClassName="component-instance"
            img="/img/line-2-4.svg"
            line="/img/line-1-9.svg"
            line1="/img/line-1-9.svg"
            line2="/img/line-2-4.svg"
            line3="/img/line-1-9.svg"
            line4="/img/line-2-4.svg"
            line5="/img/line-1-9.svg"
            line6="/img/line-2-4.svg"
            property1="default"
            to="/detail-page"
          />
          <div className="frame-26">
            <img className="america" alt="America" src="/img/america.png" />
            <div className="frame-27">
              <div className="text-wrapper-46">ព័ត៍មានថ្មីៗ</div>
              <img className="line-7" alt="Line" src="/img/line-9.svg" />
            </div>
          </div>
          <div className="frame-28">
            <img className="icon-bullhorn" alt="Icon bullhorn" src="/img/icon-bullhorn.svg" />
            <div className="frame-29">
              <div className="text-wrapper-47">ការប្រកាសថ្មីៗ</div>
              <img className="line-8" alt="Line" src="/img/line-10.svg" />
            </div>
          </div>
          <div className="right">
            <div className="datepicker">
              <CalendarAlt1 className="calendar-alt" />
              <div className="text-wrapper-48">Select Dates</div>
            </div>
          </div>
          <ComponentWrapper
            className="component-2"
            frameClassName="component-2-instance"
            img="/img/svg-4.svg"
            svg="/img/svg-3.svg"
            svg1="/img/svg-5.svg"
            to="/announcement"
          />
          <div className="group-29">
            <div className="overlap-11">
              <div className="group-30">
                <div className="group-31">
                  <div className="overlap-12">
                    <div className="text-wrapper-49">១១ តុលា ២០២៣</div>
                    <div className="overlap-group-10">
                      <p className="heading-link-2">“សន្តិភាព ថ្ងៃនេះ ជា សន្តិភាពរួម របស់ ប្រជាជាតិខ្មែរទាំងមូល”</p>
                      <div className="group-32">
                        <div className="text-wrapper-50">នាយករដ្ឋមន្ត្រី ហ៊ុន ម៉ាណែត</div>
                        <img className="line-9" alt="Line" src="/img/line-8-4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="asset-new-hero" alt="Asset hero" src="/img/asset-1new-hero-2.png" />
            </div>
          </div>
        </div>
        <div className="overlap-13">
          <img className="background-3" alt="Background" src="/img/background1-6.png" />
          <div className="group-33">
            <div className="text-wrapper-51">ភ្ជាប់ទៅកាន់គេហទំព័រ</div>
            <img className="line-10" alt="Line" src="/img/line-1-8.svg" />
          </div>
          <div className="group-34">
            <div className="overlap-14">
              <img className="cambodianflag-2" alt="Cambodianflag" src="/img/cambodianflag-design1-3-1.png" />
              <div className="group-35">
                <div className="overlap-15">
                  <footer className="footer-2">
                    <div className="overlap-group-11">
                      <img className="line-11" alt="Line" src="/img/line-7-2.svg" />
                      <div className="div-15">
                        <div className="p-2">
                          <p className="element-2">
                            <span className="text-wrapper-52">រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាង</span>
                            <span className="text-wrapper-53"> © 2023</span>
                            <span className="text-wrapper-52"> សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត</span>
                          </p>
                        </div>
                        <div className="ul-2">
                          <div className="frame-30">
                            <div className="frame-31">
                              <div className="text-wrapper-54">ព័ត៌មាន</div>
                              <div className="text-wrapper-55">សេចក្តីប្រកាស</div>
                              <div className="text-wrapper-56">សមិទ្ធិផល</div>
                              <div className="text-wrapper-57">ទំនាក់ទំនង</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </footer>
                  <div className="frame-32">
                    <div className="frame-33">
                      <div className="frame-34">
                        <div className="frame-35">
                          <div className="group-36" />
                          <p className="div-16">
                            <span className="text-wrapper-58">
                              បណ្ណាល័យរូបភាព
                              <br />
                            </span>
                            <span className="text-wrapper-58">
                              <br />
                            </span>
                            <span className="text-wrapper-58">{""}</span>
                          </p>
                          <div className="frame-36">
                            <div className="frame-37">
                              <img className="image-12" alt="Image" src="/img/image-14.png" />
                              <img className="image-12" alt="Image" src="/img/image-14.png" />
                              <img className="image-12" alt="Image" src="/img/image-14.png" />
                            </div>
                            <div className="frame-38">
                              <img className="image-13" alt="Image" src="/img/image-11-2x.png" />
                              <img className="image-14" alt="Image" src="/img/image-15.png" />
                              <img className="image-14" alt="Image" src="/img/image-15.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="group-37">
                        <div className="group-38">
                          <div className="frame-39">
                            <div className="frame-40">
                              <div className="text-wrapper-59">អាសយដ្ឋាន</div>
                              <p className="text-wrapper-60">
                                អគារលេខ SD001 ផ្លួវ ២០០៤ ភូមិ តាងួន ១ សង្កាត់ កាកាប ១ ខណ្ឌពោធិ៍សែនជ័យ 120912
                                រាជធានីភ្នំពេញ
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="frame-41">
                          <p className="div-17">
                            <span className="text-wrapper-58">សារអេឡិចត្រូនិច</span>
                            <span className="text-wrapper-61">
                              {" "}
                              <br />
                            </span>
                            <span className="text-wrapper-61">
                              <br />
                            </span>
                            <span className="text-wrapper-61">{""}</span>
                          </p>
                          <div className="text-wrapper-62">info@samdechhunmanet.gov.kh</div>
                        </div>
                      </div>
                      <div className="div-footer-social-2">
                        <div className="text-wrapper-63">បណ្តាញសង្គម</div>
                        <div className="frame-42">
                          <img className="group-39" alt="Group" src="/img/group-12.png" />
                          <img className="group-39" alt="Group" src="/img/group-12.png" />
                          <img className="group-39" alt="Group" src="/img/group-12.png" />
                          <img className="group-39" alt="Group" src="/img/group-12.png" />
                          <div className="list-item-link-SVG-2">
                            <div className="symbol-11"></div>
                          </div>
                          <div className="list-item-link-SVG-2">
                            <img className="telegram-app-3" alt="Telegram app" src="/img/telegram-app.png" />
                          </div>
                          <div className="symbol-12"></div>
                          <div className="symbol-13"></div>
                          <div className="symbol-14"></div>
                          <div className="symbol-15"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="subtract-2" alt="Subtract" src="/img/subtract-1.png" />
            </div>
          </div>
          <Frame
            className="frame-77"
            href="https://mef.gov.kh/"
            href1="https://www.mod.gov.kh/"
            href2="https://www.mfaic.gov.kh/"
            href3="https://www.interior.gov.kh/"
            href4="https://www.moc.gov.kh/"
            href5="https://moeys.gov.kh/"
            href6="https://web.maff.gov.kh/"
            property1="default"
          />
        </div>
      </div>
    </div>
  );
};
