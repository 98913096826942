/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const Group = ({
  className,
  imageClassName,
  linkImageClassName,
  assetHero = "/img/asset-1new-hero-2.png",
  to,
  to1,
  to2,
  to3,
}) => {
  return (
    <div className={`group ${className}`}>
      <div className="overlap">
        <div className="div-nav-utility" />
        <div className="overlap-wrapper">
          <div className="overlap-group">
            <div className="div" />
            <div className="div-2">
              <div className="div-frame">
                <div className="link-state">
                  <div className="symbol"></div>
                </div>
                <div className="symbol-wrapper">
                  <div className="symbol"></div>
                </div>
                <div className="symbol-wrapper">
                  <div className="symbol"></div>
                </div>
                <div className="symbol-wrapper">
                  <div className="symbol"></div>
                </div>
                <div className="symbol-wrapper">
                  <div className="symbol"></div>
                </div>
                <div className="symbol-wrapper">
                  <div className="text-wrapper"></div>
                </div>
              </div>
              <div className="header">
                <div className="nav-list">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group-2">
                      <div className="text-wrapper-2">សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត</div>
                      <div className="text-wrapper-3">នាយករដ្ឋមន្ត្រី នៃព្រះរាជាណាចក្រកម្ពុជា</div>
                    </div>
                  </div>
                  <div className="frame-2">
                    <Link className="text-wrapper-4" to={to}>
                      ទំព័រដើម
                    </Link>
                    <Link className="text-wrapper-5" to={to1}>
                      ជីវប្រវត្តិ
                    </Link>
                    <Link className="text-wrapper-5" to={to2}>
                      ចក្ខុវិស័យ
                    </Link>
                    <Link className="text-wrapper-6" to={to3}>
                      ទំនាក់ទំនង
                    </Link>
                    <div className="div-flex">
                      <div className="link">
                        <div className="div-flex-wrapper">
                          <div className="image-wrapper">
                            <div className={`image ${imageClassName}`} />
                          </div>
                        </div>
                      </div>
                      <div className={`link-image ${linkImageClassName}`} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="asset-hero" alt="Asset hero" src={assetHero} />
        <img className="asset-wat" alt="Asset wat" src="/img/asset-1angkor-wat-1.png" />
      </div>
    </div>
  );
};

Group.propTypes = {
  assetHero: PropTypes.string,
  to: PropTypes.string,
  to1: PropTypes.string,
  to2: PropTypes.string,
  to3: PropTypes.string,
};
