/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const FrameWrapper = ({ className }) => {
  return (
    <div className={`frame-wrapper ${className}`}>
      <div className="fluent-card">
        <div className="frame-7">
          <img className="image-3" alt="Image" src="/img/image-10.png" />
          <p className="text-wrapper-19">
            Letter of Greetings to Samdech Krala Hom Sar Kheng, Deputy Prime Minister and Minister of Interior
          </p>
          <div className="text-wrapper-20">2nd Mar 2023</div>
          <img className="icon-facebook" alt="Icon facebook" src="/img/icon-facebook.png" />
          <img className="vector" alt="Vector" src="/img/vector.svg" />
        </div>
      </div>
      <div className="fluent-card">
        <div className="frame-7">
          <img className="image-3" alt="Image" src="/img/image-10.png" />
          <p className="text-wrapper-19">
            Letter of Greetings to Samdech Krala Hom Sar Kheng, Deputy Prime Minister and Minister of Interior
          </p>
          <div className="text-wrapper-20">2nd Mar 2023</div>
          <img className="icon-facebook" alt="Icon facebook" src="/img/icon-facebook.png" />
          <img className="vector" alt="Vector" src="/img/vector.svg" />
        </div>
      </div>
      <div className="fluent-card">
        <div className="frame-7">
          <img className="image-3" alt="Image" src="/img/image-10.png" />
          <p className="text-wrapper-19">
            Letter of Greetings to Samdech Krala Hom Sar Kheng, Deputy Prime Minister and Minister of Interior
          </p>
          <div className="text-wrapper-20">2nd Mar 2023</div>
          <img className="icon-facebook" alt="Icon facebook" src="/img/icon-facebook.png" />
          <img className="vector" alt="Vector" src="/img/vector.svg" />
        </div>
      </div>
      <div className="fluent-card">
        <div className="frame-7">
          <img className="image-3" alt="Image" src="/img/image-10.png" />
          <p className="text-wrapper-19">
            Letter of Greetings to Samdech Krala Hom Sar Kheng, Deputy Prime Minister and Minister of Interior
          </p>
          <div className="text-wrapper-20">2nd Mar 2023</div>
          <img className="icon-facebook" alt="Icon facebook" src="/img/icon-facebook.png" />
          <img className="vector" alt="Vector" src="/img/vector.svg" />
        </div>
      </div>
    </div>
  );
};
