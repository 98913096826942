/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ComponentVariant = ({
  className,
  frameClassName,
  divImage = "/img/div-image-4.png",
  img = "/img/div-image-4.png",
  divImage1 = "/img/div-image-4.png",
  divImage2 = "/img/div-image-4.png",
}) => {
  return (
    <div className={`component-variant ${className}`}>
      <div className={`frame-9 ${frameClassName}`}>
        <div className="frame-10">
          <img className="div-image" alt="Div image" src={divImage} />
          <p className="heading-president">Pentagon Strategy - Phase 1 has two main historical missions:</p>
          <div className="frame-11">
            <p className="first-to-maintain">
              <span className="span">First</span>
              <span className="text-wrapper-22">
                , to maintain and manage the peace group achieved by the invaluable vulnerabilities and to accumulate
                the momentum of economic growth and overall.
              </span>
            </p>
            <p className="second-to-build-and">
              <span className="span">Second</span>
              <span className="text-wrapper-22">
                , to build and strengthen the foundation for accelerating the development of the country by going
                through the milestones of becoming a high middle-income country by 2030, towards achieving the vision of
                Cambodia by 2050.
              </span>
            </p>
          </div>
        </div>
        <div className="frame-10">
          <img className="div-image" alt="Div image" src={img} />
          <p className="heading-president">Pentagon Strategy - Phase 1 has two main historical missions:</p>
          <div className="frame-11">
            <p className="first-to-maintain">
              <span className="span">First</span>
              <span className="text-wrapper-22">
                , to maintain and manage the peace group achieved by the invaluable vulnerabilities and to accumulate
                the momentum of economic growth and overall.
              </span>
            </p>
            <p className="second-to-build-and">
              <span className="span">Second</span>
              <span className="text-wrapper-22">
                , to build and strengthen the foundation for accelerating the development of the country by going
                through the milestones of becoming a high middle-income country by 2030, towards achieving the vision of
                Cambodia by 2050.
              </span>
            </p>
          </div>
        </div>
        <div className="frame-10">
          <img className="div-image" alt="Div image" src={divImage1} />
          <p className="heading-president">Pentagon Strategy - Phase 1 has two main historical missions:</p>
          <div className="frame-11">
            <p className="first-to-maintain">
              <span className="span">First</span>
              <span className="text-wrapper-22">
                , to maintain and manage the peace group achieved by the invaluable vulnerabilities and to accumulate
                the momentum of economic growth and overall.
              </span>
            </p>
            <p className="second-to-build-and">
              <span className="span">Second</span>
              <span className="text-wrapper-22">
                , to build and strengthen the foundation for accelerating the development of the country by going
                through the milestones of becoming a high middle-income country by 2030, towards achieving the vision of
                Cambodia by 2050.
              </span>
            </p>
          </div>
        </div>
        <div className="frame-10">
          <img className="div-image" alt="Div image" src={divImage2} />
          <p className="heading-president">Pentagon Strategy - Phase 1 has two main historical missions:</p>
          <div className="frame-11">
            <p className="first-to-maintain">
              <span className="span">First</span>
              <span className="text-wrapper-22">
                , to maintain and manage the peace group achieved by the invaluable vulnerabilities and to accumulate
                the momentum of economic growth and overall.
              </span>
            </p>
            <p className="second-to-build-and">
              <span className="span">Second</span>
              <span className="text-wrapper-22">
                , to build and strengthen the foundation for accelerating the development of the country by going
                through the milestones of becoming a high middle-income country by 2030, towards achieving the vision of
                Cambodia by 2050.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

ComponentVariant.propTypes = {
  divImage: PropTypes.string,
  img: PropTypes.string,
  divImage1: PropTypes.string,
  divImage2: PropTypes.string,
};
