import React from "react";
import { Link } from "react-router-dom";
import { Group } from "../../components/Group";
import "./style.css";

export const Vision = () => {
  return (
    <div className="vision">
      <div className="div-10">
        <div className="group-2">
          <Link to="/vision">
            <button className="button">PENTAGON STRATEGY</button>
          </Link>
          <div className="button-statements">QUOTES</div>
        </div>
        <div className="group-3">
          <div className="overlap-group-5">
            <img className="line-2" alt="Line" src="/img/line-11.svg" />
            <img className="line-3" alt="Line" src="/img/line-12.svg" />
          </div>
        </div>
        <Group className="group-15" to="/home" to1="/biography-1" to2="/vision" to3="/contact" />
        <div className="group-4">
          <div className="overlap-3">
            <img className="cambodianflag" alt="Cambodianflag" src="/img/cambodianflag-design1-3-1.png" />
            <div className="group-5">
              <div className="overlap-4">
                <footer className="footer">
                  <div className="overlap-group-6">
                    <img className="line-4" alt="Line" src="/img/line-7-1.svg" />
                    <div className="div-11">
                      <div className="element-wrapper">
                        <p className="element">
                          <span className="text-wrapper-25">រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាង</span>
                          <span className="text-wrapper-26"> © 2023</span>
                          <span className="text-wrapper-25"> សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត</span>
                        </p>
                      </div>
                      <div className="ul">
                        <div className="frame-12">
                          <div className="frame-13">
                            <div className="text-wrapper-27">ព័ត៌មាន</div>
                            <div className="text-wrapper-28">សេចក្តីប្រកាស</div>
                            <div className="text-wrapper-29">សមិទ្ធិផល</div>
                            <div className="text-wrapper-30">ទំនាក់ទំនង</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
                <div className="frame-14">
                  <div className="frame-15">
                    <div className="frame-16">
                      <div className="frame-17">
                        <div className="group-6" />
                        <p className="div-12">
                          <span className="text-wrapper-31">
                            បណ្ណាល័យរូបភាព
                            <br />
                          </span>
                          <span className="text-wrapper-31">
                            <br />
                          </span>
                          <span className="text-wrapper-31">{""}</span>
                        </p>
                        <div className="frame-18">
                          <div className="frame-19">
                            <img className="image-7" alt="Image" src="/img/image-14.png" />
                            <img className="image-7" alt="Image" src="/img/image-14.png" />
                            <img className="image-7" alt="Image" src="/img/image-14.png" />
                          </div>
                          <div className="frame-20">
                            <img className="image-8" alt="Image" src="/img/image-11-2x.png" />
                            <img className="image-9" alt="Image" src="/img/image-15.png" />
                            <img className="image-9" alt="Image" src="/img/image-15.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="group-7">
                      <div className="group-8">
                        <div className="frame-21">
                          <div className="frame-22">
                            <div className="text-wrapper-32">អាសយដ្ឋាន</div>
                            <p className="text-wrapper-33">
                              អគារលេខ SD001 ផ្លួវ ២០០៤ ភូមិ តាងួន ១ សង្កាត់ កាកាប ១ ខណ្ឌពោធិ៍សែនជ័យ 120912
                              រាជធានីភ្នំពេញ
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="frame-23">
                        <p className="div-13">
                          <span className="text-wrapper-31">សារអេឡិចត្រូនិច</span>
                          <span className="text-wrapper-34">
                            {" "}
                            <br />
                          </span>
                          <span className="text-wrapper-34">
                            <br />
                          </span>
                          <span className="text-wrapper-34">{""}</span>
                        </p>
                        <div className="text-wrapper-35">info@samdechhunmanet.gov.kh</div>
                      </div>
                    </div>
                    <div className="div-footer-social">
                      <div className="text-wrapper-36">បណ្តាញសង្គម</div>
                      <div className="frame-24">
                        <img className="group-9" alt="Group" src="/img/group-6-2x.png" />
                        <img className="group-9" alt="Group" src="/img/group-6-2x.png" />
                        <img className="group-9" alt="Group" src="/img/group-6-2x.png" />
                        <img className="group-9" alt="Group" src="/img/group-6-2x.png" />
                        <div className="list-item-link-SVG">
                          <div className="symbol-4"></div>
                        </div>
                        <div className="list-item-link-SVG">
                          <img className="telegram-app" alt="Telegram app" src="/img/telegram-app.png" />
                        </div>
                        <div className="symbol-5"></div>
                        <div className="symbol-6"></div>
                        <div className="symbol-7"></div>
                        <div className="symbol-8"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="subtract" alt="Subtract" src="/img/subtract-1.png" />
          </div>
        </div>
        <img className="button-SVG" alt="Button SVG" src="/img/button-svg.svg" />
        <div className="frame-25">
          <div className="link-wrapper">
            <div className="link-2">
              <p className="heading-president-2">“សន្តិភាព ថ្ងៃនេះ ជា សន្តិភាពរួម របស់ ប្រជាជាតិខ្មែរទាំងមូល”</p>
              <div className="text-wrapper-37">១១ តុលា ២០២៣</div>
              <div className="text-wrapper-38">នាយករដ្ឋមន្ត្រី ហ៊ុន ម៉ាណែត</div>
              <img className="line-5" alt="Line" src="/img/line-8.svg" />
              <div className="div-image-2" />
            </div>
          </div>
          <div className="link-wrapper">
            <div className="link-2">
              <div className="heading-president-3">“ត្រឹមមានឆន្ទៈមិនគ្រប់គ្រាន់ទេ បើយើងមិនធ្វើ!”</div>
              <div className="text-wrapper-37">១១ តុលា ២០២៣</div>
              <div className="text-wrapper-38">នាយករដ្ឋមន្ត្រី ហ៊ុន ម៉ាណែត</div>
              <img className="line-5" alt="Line" src="/img/line-8.svg" />
              <div className="div-image-3" />
            </div>
          </div>
          <div className="link-wrapper">
            <div className="link-2">
              <p className="heading-president-2">“សន្តិភាព ថ្ងៃនេះ ជា សន្តិភាពរួម របស់ ប្រជាជាតិខ្មែរទាំងមូល”</p>
              <div className="text-wrapper-37">១១ តុលា ២០២៣</div>
              <div className="text-wrapper-38">នាយករដ្ឋមន្ត្រី ហ៊ុន ម៉ាណែត</div>
              <img className="line-5" alt="Line" src="/img/line-8.svg" />
              <div className="div-image-4" />
            </div>
          </div>
          <div className="link-wrapper">
            <div className="link-2">
              <div className="heading-president-3">“ត្រឹមមានឆន្ទៈមិនគ្រប់គ្រាន់ទេ បើយើងមិនធ្វើ!”</div>
              <div className="text-wrapper-37">១១ តុលា ២០២៣</div>
              <div className="text-wrapper-38">នាយករដ្ឋមន្ត្រី ហ៊ុន ម៉ាណែត</div>
              <img className="line-5" alt="Line" src="/img/line-8.svg" />
              <div className="div-image-5" />
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="overlap-5">
            <div className="div-absolute" />
            <div className="heading-president-4">
              ត្រឹមមានឆន្ទ: មិនគ្រប់គ្រាន់ទេបើយើងមិនធ្វើ!|
              <br />
              សម្តេចមហាបវរធិបតី ហ៊ុន ម៉ាណែត
            </div>
            <img className="screenshot-10" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-2.gif" />
          </div>
        </div>
      </div>
    </div>
  );
};
