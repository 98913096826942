/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Frame = ({ property1, className, href, href1, href2, href3, href4, href5, href6 }) => {
  return (
    <div className={`frame ${className}`}>
      <div className="div-3">
        <div className="div-4">
          <a href={href} rel="noopener noreferrer" target="_blank">
            <img className="screenshot-3" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-1-4.png" />
          </a>
          <div className="text-wrapper-12">ក្រសួងសេដ្ឋកិច្ចនិងហិរញ្ញវត្ថុ</div>
        </div>
        <div className="div-5">
          <a href={href1} rel="noopener noreferrer" target="_blank">
            <img className="screenshot-4" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-1-5.png" />
          </a>
          <div className="text-wrapper-13">ក្រសួងការពារជាតិ</div>
        </div>
        <div className="overlap-group-wrapper-2">
          <div className="overlap-group-4">
            <a href={href2} rel="noopener noreferrer" target="_blank">
              <img className="screenshot-5" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-1.png" />
            </a>
            <div className="text-wrapper-14">ក្រសួងការបរទេស និងសហប្រតិបត្តិការអន្តរជាតិ</div>
          </div>
        </div>
        <div className="div-6">
          <div className="overlap-2">
            <div className="ellipse" />
            <a href={href3} rel="noopener noreferrer" target="_blank">
              <img className="screenshot-6" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-1-1.png" />
            </a>
          </div>
          <div className="text-wrapper-15">ក្រសួងមហាផ្ទៃ</div>
        </div>
        <div className="div-7">
          <div className="text-wrapper-16">ក្រសួងពាណិជ្ជកម្ម</div>
          <a href={href4} rel="noopener noreferrer" target="_blank">
            <img className="screenshot-7" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-1-6.png" />
          </a>
        </div>
        <div className="div-8">
          <a href={href5} rel="noopener noreferrer" target="_blank">
            <img className="screenshot-8" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-1-7.png" />
          </a>
          <div className="text-wrapper-17">ក្រសួងអប់រំ យុវជន និងកីឡា</div>
        </div>
        <div className="div-9">
          <a href={href6} rel="noopener noreferrer" target="_blank">
            <img className="screenshot-9" alt="Screenshot" src="/img/screenshot-2023-10-18-at-4-48-1-8.png" />
          </a>
          <div className="text-wrapper-18">ក្រសួងកសិកម្ម រុក្ខាប្រមាញ់ និងនេសាទ</div>
        </div>
      </div>
    </div>
  );
};

Frame.propTypes = {
  property1: PropTypes.oneOf(["default"]),
  href: PropTypes.string,
  href1: PropTypes.string,
  href2: PropTypes.string,
  href3: PropTypes.string,
  href4: PropTypes.string,
  href5: PropTypes.string,
  href6: PropTypes.string,
};
