import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home } from "./screens/Home";
import { Vision } from "./screens/Vision";
import { Biography } from "./screens/Biography";
import { Announcement } from "./screens/Announcement";
import { Contact } from "./screens/Contact";
import { VisionScreen } from "./screens/VisionScreen";
import { DetailPage } from "./screens/DetailPage";
import { DivWrapper } from "./screens/DivWrapper";
import { Screen8 } from "./screens/Screen8";
import { BiographyScreen } from "./screens/BiographyScreen";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/vision1",
    element: <Vision />,
  },
  {
    path: "/biography-1",
    element: <Biography />,
  },
  {
    path: "/announcement",
    element: <Announcement />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/vision",
    element: <VisionScreen />,
  },
  {
    path: "/detail-page",
    element: <DetailPage />,
  },
  {
    path: "/vision-1",
    element: <DivWrapper />,
  },
  {
    path: "/vision-2",
    element: <Screen8 />,
  },
  {
    path: "/biography-2",
    element: <BiographyScreen />,
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
